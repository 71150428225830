.card {
    margin-bottom: 0;
}

.modal-dialog {
    margin-bottom: 8px !important;

    @media (min-width: 576px) {
        margin-bottom: 28px !important;
    }

    > .modal-content {

        > .modal-header {

            > .modal-title {
                margin: 0;
            }
        }

        > .modal-body {
            margin-bottom: 0;

            .image-text-inline {

                > .text {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            form {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: $gap-16;
            }
        }

        > .modal-footer {
            gap: $gap-16;
        }
    }
}

.spinner-border.text-success {
    color: $green !important;
    width: 14px;
    height: 14px;
    margin: 0;

    @include dimension(tablet) {
        width: 18px;
        height: 18px;
    }
}