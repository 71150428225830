#home {
}

#about {
    
}

#services {

    > .service {
        
        > img {
            box-shadow: $shadow;
        }

        &.taping {

            > .text {

                > ul {
                    @include dimension(laptop) {
                        padding-left: $gap-16;
                    }
                }
            }
        }

        &.aid {

           > .boxes {

                > ul {
                    @include dimension(laptop) {
                        padding: $gap-16;
                    }
                }
            }
        }
    }

    > .price {
        text-align: center;

        > img {
            box-shadow: $shadow;
        }
    }
}

#products {

    > .product {
    
        &#scranio {
    
            > img {
                align-self: center;
            }
        }
    }
}

#contact {

    > .contact {
        display: flex;
        flex-direction: column;
        gap: $gap-8;

        @include dimension(laptop) {
            //flex-direction: row;
            justify-content: center;
            gap: $gap-32;
        }

        > .contact-info {
            display: flex;
            flex-direction: column;

            @include dimension(laptop) {
                align-self: center;
            }

            > .icon-text-inline {

                @include dimension(laptop) {
                    justify-content: center;
                }
            }
        }
    }
    
    > .form-map {
        display: flex;
        flex-direction: column;
        gap: $gap-16;

        @include dimension(laptop) {
            //flex-direction: row;
            justify-content: center;
        }

        > .contact-form {

            @include dimension(laptop) {
                width: 100%;
            }

            > form {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                
                @include dimension(laptop) {
                    margin: 0;
                }
            }
        }

        > .location {
            width: 100%;

            > .icon-text-inline {
                margin-bottom: $gap-16;
            }

            > #map {
                height: 600px;
            }
        }
    }
}

#terms-and-conditions {
    text-align: center;

    @include dimension(laptop) {
        text-align: start;
    }

    > h2 {
        font-family: 'Lato', sans-serif;
    }
}