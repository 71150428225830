@mixin dimension($size) {
    @if $size == mobile-sm {
        @media (max-width: #{$mobile-sm}) {
            @content;
        }
    } @else if $size == mobile-md {
        @media (min-width: #{$mobile-md}) {
            @content;
        }
    } @else if $size == mobile-lg {
        @media (min-width: #{$mobile-lg}) {
            @content;
        }
    } @else if $size == tablet {
        @media (min-width: #{$tablet}) {
            @content;
        }
    } @else if $size == laptop {
        @media (min-width: #{$laptop}) {
            @content;
        }
    } @else if $size == laptop-lg {
        @media (min-width: #{$laptop-lg}) {
            @content;
        }
    } @else if $size == fourk {
        @media (min-width: #{$fourk}) {
            @content;
        }
    }
}