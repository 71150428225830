
#root {
    margin-bottom: 0;
}

#app {
    
}

#content {
    margin-bottom: 0;

    @include dimension(laptop) {
        max-width: $laptop;
        margin: 0 auto;
   }
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #000;
    font-size: $font-size;

    @include dimension(tablet) {
         font-size: $font-size-tablet;
    }
}

h1, h2 {
    font-size: 3em;
    font-family: 'Allura', cursive;
    font-weight: 400;
    text-align: center;
}

h2 {
    font-size: 2.5em;
}

h3 {
    font-size: 1.25em;
}

h1, h2, h3, h4, h5, h6 {
    margin-left: $gap-16;
    margin-right: $gap-16;
    margin-bottom: $gap-16;

    @include dimension(tablet) {
        margin-left: 0;
        margin-right: 0;
    }
}

hr {
    border-top: 1px dotted $green;
}

section, form {
    margin: 0 $gap-16;
}

section, ul, div, form {
    margin-bottom: $gap-32;
}

p, footer {
    margin-bottom: $gap-16;
}

p:last-child, div:last-child, ul:last-child, form:last-child {
    margin-bottom: 0;
}

img {
    width: 100%;
    max-width: 425px;
}

a {
    text-decoration: none;
    color: #000;
    
    &:hover {
        color: $green;
        transition: $hover;
    }
}

address {
    font-style: unset;
    margin: 0;
}

ul {
    padding: 0;
    text-align: center;
    list-style-position: inside;

    // @include dimension(laptop) {
    //     list-style-position: unset;
    //     text-align: start;
    // }
}

nav {
    margin-bottom: $gap-32;
}

input, textarea {
    font-family: 'Lato', sans-serif;
    border: 1px solid $gray;
    border-radius: $border-radius;
    padding: $gap-8;
    font-size: 1em;
    margin-bottom: $gap-8;
}

input:focus, textarea:focus {
    outline: 0;
    border-color: $green;
    box-shadow: 0 0 8px rgba(8, 138, 41, 1);
}

input[type=submit] {
    background-color: $gray;
    padding: $gap-16;
    border: 1px solid $green;
    cursor: pointer;
    margin: 0;
    box-shadow: $shadow;

    &:hover {
        background-color: $green;
        color: #fff;
        transition: $hover;
        outline: 0;
        box-shadow: 0 0 8px rgba(8, 138, 41, 1);
    }
}

input[type=radio] {
    accent-color: $green;
}

textarea {
    resize: vertical;
}

#header {
    margin-top: $gap-16;
    display: flex;
    flex-direction: row;
    gap: $gap-16;
    justify-content: center;

    > svg {
        align-self: center;
        font-size: 2em;
        color: $green;
    }

    > div {
        text-align: center;

        > p {
            margin: 0;
        }

        > a {
            display: block;
            margin: 0;

            &.phone {
                color: $green;
            }
        }
    }
}

#navbar {
    z-index: 1;
    position: sticky;
    padding: $gap-16;
    top: 0;
    display: flex;
    gap: $gap-32;
    flex-flow: row nowrap;
    background-color: #fff;
    box-shadow: $shadow;
    justify-content: space-between;

    > .links {
        display: none;
        align-self: center;
        font-size: 0.95em;
        margin-bottom: 0;

        @include dimension(laptop) {
            display: flex;
            flex-direction: row;
            gap: $gap-32;
        }
    }

    .hamburger {
        display: block;

        @include dimension(laptop) {
            display: none;
        }
    }
}

#sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $gray;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s;
    box-shadow: $shadow-right;

    > .close {
        position: absolute;
        top: $gap-16;
        right: $gap-16;
        margin-left: $gap-64;
        border: none;
        font-size: 1.25em;
        background-color: transparent;
        cursor: pointer;
    
        > svg {
            color: #fff;
        }
    }

    > a {

        > .logo {
            margin-left: $gap-32;
            margin-bottom: $gap-16;
            width: 211px;
            background-color: #fff;
            box-shadow: $shadow;
        }
    }

    > .icon-text-inline {
        justify-content: flex-start;
        padding: $gap-16 $gap-16 $gap-16 $gap-32;

        &:hover {
            transition: $hover;
            background-color: #fff;

            > svg {
                color: $green;
            }

            > a {
                color: $green;
            }
        }

        > svg {
            color: $green;
        }
        
        > a {
            font-weight: bold;
            color: #000;
        }
    }
}

#footer {
    text-align: center;

    > h3 {
        margin-bottom: $gap-32;
    }

    > .instagram {
        margin-bottom: $gap-8;

        @include dimension(laptop) {
            margin-bottom: $gap-16;
        }
    }

    > .contact {
        display: flex;
        flex-direction: column;
        gap: $gap-8;

        @include dimension(laptop) {
            //flex-direction: row;
            justify-content: center;
            //gap: $gap-32;
        }
    }

    > .insurances {
        display: flex;
        flex-direction: column;
        gap: $gap-16;
        align-items: center;

        @include dimension(laptop) {
            flex-direction: row;
            justify-content: center;
            gap: $gap-32;
        }

        > img {
            width: 225px;
        }
    }
}

#copyright {
    background-color: $green;
    padding: $gap-16;
    
    > p {
        text-align: center;
        color: #fff;
    }
}