.side-margin {
    margin-right: $gap-16;
    margin-left: $gap-16;
    margin-bottom: $gap-16;

    @include dimension(laptop) {
        margin-right: 0;
        margin-left: 0;
    }
}

.box-shadow {
    box-shadow: $shadow;
}

.h2-lato {
    font-family: 'Lato', sans-serif;
    font-size: 1.75em;
}

.center {
    text-align: center;
}

.ul-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.youtube {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    > .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.icon-text-inline {
    display: flex;
    flex-direction: row;
    gap: $gap-8;
    justify-content: center;
    margin: 0;

    > svg {
        align-self: center;
    }
}

.image-text-inline {
    display: flex;
    flex-direction: column;
    gap: $gap-16;

    @include dimension(laptop) {
        gap: $gap-32;
    }

    > img {
        align-self: center;
        max-width: 425px;
    }

    @include dimension(laptop) {
        //flex-direction: row;
        justify-content: center;
    }

    > .text {
        text-align: center;
        align-self: center;
        margin: 0 $gap-16;

        @include dimension(laptop) {
            margin: 0;
            text-align: justify;
        }

        > button {
            text-align: center;
        }
    }
}

.round-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-8;
    margin-bottom: $gap-16;

    > img {
        border-radius: 100%;
        height: 192px;
        width: 192px;
    }

    > p {

    }
}

.boxes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0;

    @include dimension(tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: $gap-16;
    }

    > ul {
        margin-bottom: 0;

        @include dimension(tablet) {
            border-radius: $border-radius;
            background-color: $gray;
            text-align: start;
            box-shadow: $shadow;
            padding: $gap-16;
        }

        @include dimension(laptop) {
            list-style-position: inside;
        }
    }
}

.button {
    padding: $gap-16;
    border: 1px solid $green;
    border-radius: $border-radius;
    width: $button-width;
    margin-right: auto;
    margin-left: auto;
    box-shadow: $shadow;
    cursor: pointer;
    background-color: $gray;
    text-align: center;

    &:hover {
        background-color: $green;
        transition: $hover;
        outline: 0;
        box-shadow: 0 0 8px rgba(8, 138, 41, 1);

        > a {
            color: #fff;
        }
    }
}

.button-button {
    text-align: center;
    margin: 0;

    > button {
        padding: $gap-16;
        border: 1px solid $green;
        border-radius: $border-radius;
        width: $button-width;
        margin-right: auto;
        margin-left: auto;
        box-shadow: $shadow;
        cursor: pointer;
        background-color: $gray;
        text-align: center;

        &:hover {
            background-color: $green;
            transition: $hover;
            outline: 0;
            box-shadow: 0 0 8px rgba(8, 138, 41, 1);
            color: #fff;
        }
    }
}

.radio-button {
    display: flex;
    flex-direction: row;

    > input[type=radio]  {
        align-self: center;
        margin-right: $gap-8;
        margin-bottom: 0;
    }
}

.counter {
    display: flex;
    flex-direction: row;
    margin-bottom: $gap-16;

    > .button-button {

        > button {
            width: 64px;
        }
    }

    > input[type=number] {
        width: 128px;
        margin-bottom: 0;
        text-align: center;
    }
}

.hamburger {
    width: 81px;
    border: none;
    background-color: #fff;
    cursor: pointer;

    &:hover {
         
        > svg {
            transition: $hover;
            color: $gray;
        }
    }

    > svg {
        font-size: 42px;
        color: $green;
    }
}