$mobile-sm: 320px;
$mobile-md: 375px;
$mobile-lg: 425px;
$tablet: 768px;
$laptop: 1024px;
$laptop-lg: 1440px;
$fourk: 2560px;

$laptop-half: calc(#{$laptop} / 2);

$gap-8: 8px;
$gap-16: 16px;
$gap-32: 32px;
$gap-48: 48px;
$gap-64: 64px;

$font-size: 14px;
$font-size-tablet: 18px;

$green: #088a29;
$gray: #EFEFEF;

$hover: 0.3s ease-out;

$shadow: 0 0 2px rgb(0 0 0 / 16%), 0 5px 10px rgb(0 0 0 / 14%);
$shadow-right: 2px 0 2px -2px rgb(0 0 0 / 16%), 0 5px 10px rgb(0 0 0 / 14%);

$button-width: 196px;

$border-radius: 4px;